import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Container, Button } from '@mui/material';
// components
import { MotionContainer } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

const TermsAndConditions = () => (
  <RootStyle title="Terms & Conditions | ZetuPlay App">
  <Container>
    <MotionContainer initial="initial" open>
      <Box sx={{ margin: 'auto', textAlign: 'left' }}>
        <Typography variant="h4" paragraph>
          Terms and Condition
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          ZetuPlay is a service developed, owned and maintained by Infiniti Tech Capital
          Limited.
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          By accessing this portal you agree to and acknowledge that you have read, understood and
          agree to abide by and bound by these terms and conditions
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          ZetuPlay reserves the right to modify the terms and conditions ("Terms") of the Service
          and Disclaimer including without limitation the right to suspend, discontinue or cancel
          the Service without any prior notification. You are advised to regularly review the
          Terms. If you do not agree with any of the Terms and any amendments thereto, you must
          not use this site/service.
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          The Content (i.e., games, data, software, text, information, images, sounds, music,
          photographs, communications or other materials) provided on this site is intended for
          personal non-commercial use. The Content provided through Service is owned by ZetuPlay,
          its affiliates, third party content providers with whom ZetuPlay has a business
          relationship. The Copyright in the Content provided through this Service is held by
          ZetuPlay or the third party content provider or by the original creator of the Content.
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          ZetuPlay will endeavor to provide rightfully sourced content with focus on ethnic and
          regional sentiments / commitments. In the event all or any part of the supplied
          content(s), and or its use or application, is/are found to be offensive, it is purely
          unintentional You also agree that any content or material transmitted through the use of
          this Service will be at your sole risk and that ZetuPlay shall not be responsible for
          any harm or damage resulting to your computer system, mobile phone, or any other device
          or loss of data that results from such transmission or download.
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          By subscribing to ZetuPlay, you accept to receive SMS texts and SMS links on your
          mobile number for all content related to games and promotional SMS content
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          To enjoy/play the services/games, the user needs to be an active subscriber where the
          subscription charges are Kshs 15 per day. This
          is an auto renewal service
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          All other standard operator terms and conditions apply.
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          Please note data charges will apply as per your tariff plan.
        </Typography>
      </Box>
      <Box sx={{ margin: 'auto', textAlign: 'center' }}>
        <Button
          sx={{ width: '280px' }}
          to="/"
          size="large"
          variant="contained"
          component={RouterLink}
        >
          Subscribe
        </Button>
      </Box>
    </MotionContainer>
  </Container>
</RootStyle>
);

export default TermsAndConditions;
