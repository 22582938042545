import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, Box } from '@mui/material';
import Page from '../components/Page';
import { LoginForm, ConfirmOtpForm } from '../components/authentication/login';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const [showOtp, setShowOtp] = useState(false);
  const [msisdn, setMsisdn] = useState('');

  return (
    <RootStyle title="Login | ZetuPlay">
      <Container maxWidth="sm">
        <ContentStyle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <img height={250} width={250} src="/static/newlogo.png" alt="login" />
          </Box>
          <Stack>
            <Typography variant="h4" gutterBottom>
              Sign in to ZetuPlay
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {showOtp ? 'Enter the OTP number sent to your phone' : 'Enter your phone number below'}.
            </Typography>
          </Stack>
          {showOtp ? (
            <ConfirmOtpForm msisdn={msisdn} setShowOtp={setShowOtp} />
          ) : (
            <LoginForm setShowOtp={setShowOtp} setMsisdn={setMsisdn} />
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
