import React from 'react';
import getUrlParamValue from 'src/utils/getUrlParamValue';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Link } from '@mui/material';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const PlayPage = () => {
  const navigate = useNavigate();
  const hashNum = getUrlParamValue('h');
  const otp = getUrlParamValue('p');
  /* React.useEffect(() => {
    syncUserData({
      variables: {
        hash: hashNum,
        msisdn: Cookies.get('msisdn'),
        otp
      }
    }).then(
      (syncResponse) => {
        console.log(syncResponse);
        navigate('/');
      },
      (error) => {
        // console.log(error);
        navigate('/auth');
      }
    );
  }, []); */

  return (
    <Page title="Subscribe | ZetuPlay App">
      <Container maxWidth="xl">
        <ContentStyle>
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                <Box component="img" src="/static/newlogo.png" sx={{ height: 200, mx: 'auto' }} />
              </motion.div>
              <motion.div variants={varBounceIn}>
                <Typography variant="h4" paragraph>
                  Welcome to Zetu Play!
                </Typography>
              </motion.div>
              <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
                We are processing your request.
              </Typography>
              <Typography variant="subtitle2" sx={{ marginTop: '4px', textAlign: 'center' }} />
            </Box>
          </MotionContainer>
        </ContentStyle>
      </Container>
    </Page>
  );
};

export default PlayPage;
