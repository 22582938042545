// scroll bar
import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// ----------------------------------------------------------------------
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include'
});



// const authLink = setContext((_, { headers }) => {
//   let msisdn = Cookies.get('msisdn');
//   if(msisdn.indexOf('254') != 0) {
//     msisdn = '';
//   }
//   // const token = localStorage.getItem('token');
//   return {
//     headers: {
//       ...headers,
//       msisdn: msisdn || ''
//       // Authorization: token ? `Bearer ${token}` : ''
//     }
//   };
// });

/* const userToken = localStorage.getItem('token');
if (userToken) {
  const decodedToken = jwtDecode(userToken);
  // TODO: fix this, refreshToken needs implementation too
  const userFreePlay = true;
  const userFreePlayExpiry = 1230;
} */

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache()
});

window.apolloClientStupidShitThatPreviuousDeveloperJustHadToFuckingUse = client;

ReactDOM.render(
  <ApolloProvider client={client}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
reportWebVitals();
