import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

const NotSubscribed = () => (
  <RootStyle title="Not Subscribed | ZetuPlay App">
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Box component="img" src="/static/newlogo.png" sx={{ height: 260, mx: 'auto' }} />
          </motion.div>
          <motion.div variants={varBounceIn}>
            <Typography variant="h4" paragraph>
              Not subscribed.
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            Sorry, you have exceeded your daily free playing minutes. Please subscribe to continue
            playing.
          </Typography>

          <Button to="/subscribe" size="large" variant="contained" component={RouterLink}>
            Proceed to Subscription Page
          </Button>
        </Box>
      </MotionContainer>
    </Container>
  </RootStyle>
);

export default NotSubscribed;
