import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Alert } from '@mui/material';
import Cookies from 'js-cookie';
import { useQuery } from '@apollo/client';
import { checkHashHeaderSync } from 'src/utils/checkHashHeader';
import { styled } from '@mui/material/styles';
import TagManager from 'react-gtm-module';
import Subscribe from '../components/Subscribe';
import Spinner from '../components/Spinner';
import Page from '../components/Page';
import { GET_USER_STATUS } from '../graphql';
import getErrorCode from '../utils/getErrorCode';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const Home = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_USER_STATUS, {
    fetchPolicy: 'network-only',
  });
  const [showError, setShowError] = useState(false); // State to manage error display after delay

  useEffect(() => {
    const checkUserStatus = async () => {
      try {


        const msisdnCookie = Cookies.get('msisdn');

        if (msisdnCookie && msisdnCookie.length > 0) {
          console.log('MSISDN cookie found. Skipping the MSISDN request.');
          console.log(msisdnCookie);

          navigate('/open/games');

          return;  // Skip the request since MSISDN cookie is available
        }

        console.log('Checking user status...');

        const promise = await checkHashHeaderSync();

        if (promise === 'no msisdn') {
          console.log('No MSISDN found. Navigating to /auth...');

          Cookies.set('msisdn', "");
          Cookies.set('msisdnHash', "");
          navigate('/auth');
          return;
        }

        const msisdn = promise || ''; // Fallback to an empty string if undefined
        localStorage.setItem('msisdn', msisdn);
        localStorage.setItem('msisdnHash', promise);
        Cookies.set('msisdn',msisdn);

        // Cookies.set('msisdn', msisdn);
        // console.log('MSISDN stored:', msisdn);

        if (!msisdn || typeof msisdn !== 'string') {
          navigate('/auth');
          return;
        }

        if (data && data.getUserStatus) {
          const { msisdn, responseCode } = data.getUserStatus;

          switch (responseCode) {
            case 200:
              localStorage.setItem('subscribed', 'true');
              TagManager.dataLayer({
                dataLayer: {
                  event: 'userSubscribed',
                  userId: msisdn,
                },
              });
              setTimeout(() => {
                navigate('/app/games');
              }, 1000);
              break;

            case 100:
            case 400:
              localStorage.setItem('subscribed', '');
              localStorage.setItem('newUser', 'newUser');
              navigate('/open/games');
              setTimeout(() => {
                navigate('/subscribe?m=unable');
              }, 1800000);
              break;

            case 600:
              localStorage.setItem('subscribed', '');
              navigate('/subscribe?m=unable');
              break;

            case 500:
              localStorage.setItem('subscribed', '');
              navigate('/not-subscribe');
              break;

            default:
              Cookies.set('msisdn', '');
              localStorage.setItem('msisdn', '');
              navigate('/auth');
              break;
          }
        }
      } catch (err) {
        console.error('Error:', err);
        navigate('/he-disable');
      }
    };

    // if (!loading && !error) {
    //   checkUserStatus();
    // } else if (error) {
    //   checkUserStatus(); // Retry user status check on error

    //   console.error('GraphQLErrors:', error.graphQLErrors);
    //   console.error('Error Code:', getErrorCode(error));
    //   console.error('Error:', error);
    //   // checkUserStatus(); // Retry user status check on error
    // }
    const handleUserStatusCheck = async () => {
      if (!loading && !error) {
        await checkUserStatus(); // Wait for checkUserStatus to complete
      } else if (error) {
        try {
          await checkUserStatus(); // Retry user status check on error
        } catch (err) {
          console.error('Error retrying user status:', err);
        }
        await checkUserStatus(); // Retry user status check on error
        setTimeout(() => {
          setShowError(true); // Delay error display for 4 seconds
        }, 4000);


        // Now log the error information after retrying
        // console.error('GraphQLErrors:', error.graphQLErrors);
        // console.error('Error Code:', getErrorCode(error));
        // console.error('Error:', error);
      }
    };
    handleUserStatusCheck()
  }, [data, loading, error, navigate]);

  return (
    <Page title="Home | ZetuPlay">
      <Container maxWidth="xl">
        <ContentStyle>
          {loading && <Spinner />}
          <Alert severity="info">Checking headers....</Alert>

          {error &&  showError &&(
            <>
              {error.graphQLErrors && getErrorCode(error) === 404 ? (
                <Subscribe code={getErrorCode(error)} />
              ) : (
                <Alert severity="error">There was an error. Please try again later.</Alert>
              )}
            </>
          )}
        </ContentStyle>
      </Container>
    </Page>
  );
};

export default Home;
