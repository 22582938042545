
import React, { useState, useEffect } from 'react';


function getBearerToken() {
  const username = process.env.REACT_APP_USERNAME; // Use environment variables for security
  const password = process.env.REACT_APP_PASSWORD; // Use environment variables for security


  // Check for missing credentials
  if (!username || !password) {
    throw new Error('Missing USERNAME or PASSWORD in environment variables');
  }

  // Prepare the data to send in the request
  const data = `Username=${encodeURIComponent(username)}&Password=${encodeURIComponent(password)}`;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) { // Request completed
        if (this.status === 200) { // Check for a successful response
          try {
            const response = JSON.parse(this.responseText); // Parse the response
            const { access_token, token_type } = response;
            resolve(`${token_type} ${access_token}`); // Resolve with Bearer token
          } catch (error) {
            reject(new Error('Error parsing JSON response: ' + error.message));
          }
        } else {
          reject(new Error('Failed to get access token. Status: ' + this.status + ' Response: ' + this.responseText));
        }
      }
    });

    // Open the request with the correct URL and method
    xhr.open("POST", "https://third-party.auth.eu-west-1.amazoncognito.com/oauth2/token?grant_type=client_credentials");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    // Set the Authorization header using base64 encoding
    const authKey = btoa(`${username}:${password}`);
    xhr.setRequestHeader("Authorization", `Basic ${authKey}`);

    // Set cookies if necessary
    const cookieValue = "XSRF-TOKEN=df9a37e0-0f7f-4d82-8dda-390a09274d8a"; // Ensure this value is valid
    xhr.setRequestHeader("Cookie", cookieValue); 

    // Send the request with the data
    xhr.send(data);
  });
}

// Function to get the MSISDN hash using XMLHttpRequest
export function checkHashHeader() {
  return new Promise(async (resolve, reject) => {
    let bearerToken;

    try {
      // Fetch the Bearer token before making the MSISDN request
      bearerToken = await getBearerToken();
    } catch (error) {
      return reject(new Error('Failed to get Bearer token: ' + error.message));
    }

    const xmlHttp = new XMLHttpRequest();

    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          try {
            const response = JSON.parse(xmlHttp.responseText);

            // Parse the response to get the MSISDN
            console.log('Data**:', response); // Log the error for debugging

            const msisdn = response.ServiceResponse.ResponseBody.Response.Msisdn;

            if (msisdn && msisdn.length > 0) {
              resolve(msisdn);  // Successfully retrieved MSISDN
            } else {
              reject(new Error('No MSISDN found in the response'));
            }
          } catch (error) {
            reject(new Error('Failed to parse MSISDN response'));
          }
        } else {
          reject(new Error('MSISDN request failed with status ' + xmlHttp.status));
        }
      }
    };

    // Make the request to fetch MSISDN with the Bearer token in the headers
    xmlHttp.open('GET', 'https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn', true);

    // Set necessary headers, including the Bearer token
    xmlHttp.setRequestHeader('Authorization', bearerToken);
    xmlHttp.setRequestHeader('X-App', 'he-partner');
    xmlHttp.setRequestHeader('X-MessageID', '1234');
    xmlHttp.setRequestHeader('X-Source-System', 'he-partner');

    // Send the request
    xmlHttp.send(null);
  });
}

// function parseHashInXML(xml) {
//   // const code = xml.getElementsByTagName('ns0:ResponseCode')?.[0]?.innerHTML;

//   const responseCode = xml.getElementsByTagName('ns0:ResponseCode')[0].textContent;


//   if (responseCode === undefined) {
//     // Reload the page if responseCode is undefined
//     location.reload();
//     return; // Reload might not return, but including this for clarity
//   }

  
//   if (responseCode === '0') {
//     const responseMsg = xml.getElementsByTagName('ns0:ResponseMsg')[0].textContent;
//     const responseRefId = xml.getElementsByTagName('ns0:ResponseRefId')[0].textContent;
//     const msisdnHash = xml.getElementsByTagName('ns1:MsisdnHash')[0].textContent;
//     const blazeSubscriber = xml.getElementsByTagName('ns1:BlazeSubscriber')[0].textContent;

//     // console.log({ responseCode, responseMsg, msisdnHash,blazeSubscriber })
//     return msisdnHash;
//   }
//   else if (responseCode === '1') {
//     console.log('Response code is 1');

//     const responseMsg = xml.getElementsByTagName('ns0:ResponseMsg')[0].textContent;
//     // Log the extracted values
//     // console.log('Response Code:', responseCode);
//     // console.log('Response Message:', responseMsg);

//     return "no msisdn";



//   } else {
//     console.log('Response code is neither 0 nor 1');
//   }
// }

export async function checkHashHeaderSync() {
  let t;
  try {
    t = await checkHashHeader();
    console.log('Successfully retrieved MSISDN:', t); // Log the retrieved MSISDN
    return t; // Return the result if successful
  } catch (error) {
    console.log('Error in checkHashHeader:', error); // Log the error for debugging
    
    return 'no msisdn'; // Return a fallback value
  }
}
// export function checkHashHeader() {
//   return new Promise((resolve, reject) => {
//     const xmlHttp = new XMLHttpRequest();
//     xmlHttp.onreadystatechange = function () {
//       if (xmlHttp.readyState == 4) {
//         if (xmlHttp.status == 200) {
//           // console.log(xmlHttp)

//           const phoneHash = parseHashInXML(xmlHttp.responseXML);
//           if (phoneHash.length > 0) {
//             resolve(phoneHash);
//           } else {
//             reject(new Error('No msisdn'));
//           }
//         } else {
//           reject(new Error('Request failed'));
//         }
//       }
//     };
//     xmlHttp.open('GET', 'http://header.safaricombeats.co.ke/', true);
//     xmlHttp.send(null);
//   });
// }


// export function userStatus(msisdnhash) {
//   return new Promise((resolve, reject) => {
//     const xmlHttp = new XMLHttpRequest();
//     xmlHttp.onreadystatechange = function () {
//       if (xmlHttp.readyState == 4) {
//         if (xmlHttp.status == 200) {

//           // const phoneHash = parseHashInXML(xmlHttp.responseXML);
//           resolve(xmlHttp.responseText);
//         } else {
//           reject(new Error('Request failed'));
//         }
//       }
//     };
//     xmlHttp.open('GET', 'http://41.72.208.46:9021/public/v1/user/${msisdnhash}', true);
//     xmlHttp.send(null);
//   });
// }


// export async function checkUserStatusSync(msisdnhash) {
//   let t;
//   t = await userStatus(msisdnhash);

//   return t;
// }



// export function gamePoaHeader() {
//   return new Promise((resolve, reject) => {
//     const xmlHttp = new XMLHttpRequest();

//     xmlHttp.onreadystatechange = function () {
//       if (xmlHttp.readyState == 4) {
//         console.log("** Request Headers:");
//         for (const header in xmlHttp.setRequestHeader) {
//           if (typeof xmlHttp.setRequestHeader[header] !== 'function') {
//             console.log(`  - ${header}: ${xmlHttp.setRequestHeader[header]}`);
//           }
//         }

//         if (xmlHttp.status == 200) {
//           console.log("** Response Headers:");
//           for (const header in xmlHttp.getAllResponseHeaders()) {
//             console.log(`  - ${header}: ${xmlHttp.getAllResponseHeaders()[header]}`);
//           }

//           resolve(xmlHttp.responseText);
//         } else {
//           reject(new Error(`Request failed with status: ${xmlHttp.status}`));
//         }
//       }
//     };

//     xmlHttp.open('GET', `http://www.gamespoa.com/`, true);

//     console.log("** Request URL:");


//     xmlHttp.send(null);
//   });
// }